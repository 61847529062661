<template>
    <v-card flat tile>
        <template v-if="active">
            <v-form @submit.prevent="submit" onSubmit="return false;" @keyup.enter.native.prevent="submit" class="mx-4 pb-6">
            <v-card-text class="px-0">
                <p class="mb-0" v-if="readonly">We need to verify your email address:</p>
                <p class="mb-0" v-if="!readonly">What is your email address?</p>
            </v-card-text>
            <v-text-field v-model="email" ref="emailInput" dense solo :color="primaryColor" placeholder="Email" :error-messages="inputError" :readonly="readonly">
                <template #prepend-inner>
                    <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                </template>
            </v-text-field>
            <v-row no-gutters justify="center" v-if="!readonly">
                <v-btn @click="submit" elevation="2" :style="primaryButtonStyle">Continue</v-btn>
            </v-row>
            </v-form>
        </template>
        <template v-if="!active">
            <!--
            <v-text-field v-model="email" dense solo flat :color="primaryColor" placeholder="Email" :error-messages="inputError" readonly>
                <template #prepend-inner>
                    <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                </template>
                <template #append-outer v-if="!readonly">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon :color="primaryColor" @click="edit()" v-on="on">
                                <span :color="primaryColor">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" size="1x"/>
                                </span>
                            </v-btn>
                        </template>
                        <span>Edit email address</span>
                    </v-tooltip>
                </template>
            </v-text-field>
            -->
            <v-card-text>
                <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                {{ email }}
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn icon :color="primaryColor" @click="edit()" v-on="on">
                            <span :color="primaryColor">
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" size="1x"/>
                            </span>
                        </v-btn>
                    </template>
                    <span>Edit email address</span>
                </v-tooltip>
            </v-card-text>
        </template>
    </v-card>
</template>

<style scoped>

</style>

<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState, mapGetters } from 'vuex';
import { isValidEmail } from '@/sdk/input';

export default {
    props: ['attr', 'value', 'required', 'readonly', 'idx', 'active', 'errors'], // attr is 'email', 'phone', etc.; value is the attribute value; required, readonly, and active are boolean; idx is non-negative integer; errors is array of string
    data: () => ({
        email: null,
        checkEmail: false,
        inputError: null,
        inputErrorTimeout: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            // session: (state) => state.session,
            focus: (state) => state.focus,
            brand: (state) => state.brand,
        }),
        ...mapGetters({
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
    },
    watch: {
        value(newValue) {
            this.email = newValue;
        },
        active(newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('emailInput'); }, 1);
                });
            }
        },
    },
    methods: {
        reset() {
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.checkEmail = false;
        },
        /*
        initView() {
            if (Array.isArray(this.value)) {
                this.editableTiers = this.value;
            } else {
                this.editableTiers = [];
            }
            if (this.editableTiers.length > 1) {
                this.editableTiers.splice(1); // flat price requires a single tier, so delete all tiers after first one
            }
            if (this.editableTiers.length > 0) {
                const tier = this.editableTiers[0];
                const flatPriceExp = Number.isInteger(tier.flat_price_exp) ? tier.flat_price_exp : -2;
                const flatPrice = Number.isInteger(tier.flat_price) ? tier.flat_price : null;
                const flatPriceDecimal = Number.isInteger(flatPrice) ? flatPrice * (10 ** flatPriceExp) : null;
                this.editableFlatPrice = flatPriceDecimal?.toString(10); // may be null
            } else {
                this.editableFlatPrice = null;
            }
            this.$nextTick(() => {
                setTimeout(() => { this.activate('editableFlatPriceInput'); }, 1);
            });
        },
        */
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        edit() {
            this.$emit('activate', { attr: this.attr, idx: this.idx });
        },
        editMode() {
            this.reset();
            this.$nextTick(() => {
                setTimeout(() => { this.activate('emailInput'); }, 1);
            });
        },
        async submit() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                if (!isValidEmail(this.email)) {
                    this.inputError = 'Please enter an email address';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$emit('input', { attr: this.attr, idx: this.idx, value: this.email });
                /*
                this.$store.commit('loading', { verifyEmail: true });
                const request = {
                    email: this.email,
                    interactionId: this.interactionId, // null on first request, value after email verification
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.signup(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);
                if (response?.status) {
                    switch (response.status) {
                    case 'check_email':
                        // email verification is required; user should check inbox
                        this.checkEmail = true;
                        if (Number.isInteger(response.verification_max_age)) {
                            this.verificationExpires = toText(response.verification_max_age);
                        } else {
                            this.verificationExpires = null;
                        }
                        break;
                    case 'setup_required':
                        // user record was created; next step is get the app or set up LoginShield
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        await this.$store.dispatch('loadSession');
                        this.$router.push({ name: 'dashboard' });
                        break;
                    case 'authenticated':
                        // user is already authenticated with same email address
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        this.$router.push({ name: 'dashboard' });
                        break;
                    case 'login_required':
                        // email is verified but user not authenticated here; redirect to login
                        this.$router.push({ name: 'login' });
                        break;
                    case 'error':
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    default:
                        console.error(`signup error: unexpected status from server: ${JSON.stringify(response.status)}`);
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    console.error('signup error: server response missing status');
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
                */
            } catch (err) {
                console.error('failed to request email verification', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.reset();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { verifyEmail: false });
            }
        },
        // cancel() {
        //     this.editMode = false;
        //     this.editableFlatPrice = null;
        //     this.editableTiers = null;
        // },
    },
    mounted() {
        // this.initView();
        if (this.value) {
            this.email = this.value;
        }
        if (this.active) {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('emailInput'); }, 1);
            });
        }
    },
};
</script>
